import React, {useState, ReactDOM, useEffect} from 'react'
import moment from 'moment'

const BookableAppointmentList = ({bookable_appointments, bookable_appointment_templates, index_link}) => {
  const [activeTab, setActiveTab] = useState('all')
  const [filteredSignups, setFilteredSignups] = useState(bookable_appointments)

  const iconEventMap = {
    call: { icon: 'icon-phone-incoming', color: 'border-success text-success' },
    email: { icon: 'icon-envelop2', color: 'border-primary text-primary' },
    voicemail: { icon: 'icon-play3', color: 'border-teal text-teal' },
    sms: { icon: 'icon-comment', color: 'border-teal text-teal' },
    note: { icon: 'icon-pencil', color: 'border-info text-info' }
  }

  useEffect(() => {
    if (activeTab === 'all') {
      setFilteredSignups(bookable_appointments)
    } else {
      setFilteredSignups(bookable_appointments.filter(bookable_appointment => bookable_appointment.kind === activeTab))
    }
  }, [activeTab])

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title"><a href={index_link} className="dropdown-item">Bookable Appointments</a></h6>
        <div className="header-elements">
          <span className="badge badge-info badge-pill">{bookable_appointments.length}</span>
        </div>
      </div>

      <ul className="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
        <li className="nav-item">
          <a href="#" className={"nav-link active"} data-toggle="tab" onClick={() => setActiveTab('all')} >All</a>
        </li>
        {bookable_appointment_templates.map((template, index) => {
          return (
            <li className="nav-item" key={index}>
              <a href={`#messages-${template}`} className={"nav-link"} data-toggle="tab" onClick={() => setActiveTab(template.name)}> {template.name} </a>
            </li>
          )
        })}
      </ul>

      <div className="card-body">
        <div className="chart mb-3" id="bullets"></div>

        <ul className="media-list">
          {filteredSignups.map((bookable_appointment, index) => {
            return (
              <li key={index} className="media">
                <div className="mr-3">
                  <a href="#" className={`btn bg-transparent rounded-pill border-2 btn-icon`}><i className="icon-user"></i></a>
                </div>

                <div className="media-body">
                  <a href={`${bookable_appointment.path}`}>{bookable_appointment.template_name}</a>
                  <div>{moment(bookable_appointment.expires_at).format('MMMM Do YYYY, h:mm:ss a')}</div>
                  <div className="text-muted" title={moment(bookable_appointment.created_at).format('MMMM Do YYYY, h:mm:ss a')}>{moment(bookable_appointment.created_at).fromNow()}</div>
                </div>

                <div className="dropdown ml-3">
                  <a href="#" className="list-icons-item dropdown-toggle" data-toggle="dropdown"></a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a href={`${bookable_appointment.edit_path}`} className="dropdown-item">Edit</a>
                    <a href={`${bookable_appointment.path}`} className="dropdown-item">Show</a>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>

  )
}

export default BookableAppointmentList;