import React, {useState, useEffect} from 'react'
import Nav from './scout/Nav'
import '../stylesheets/Signup.scss'
import StepWizard from 'react-step-wizard'
import Account from './signup/Account'
import Terms from './signup/Terms'
import Schedule from './scout/Schedule'
import Complete from './signup/Complete'

const Signup = props => {
  const [signup, setSignup] = useState(JSON?.parse(localStorage.getItem('currentSignup')) || {})
  const [serverErrors, setServerErrors] = useState(null)
  const todaysDate = new Date()

  useEffect(() => {
    if (signup) {
      let currentSignup = JSON.stringify(signup)
      localStorage.setItem('currentSignup', currentSignup)
      // console.debug('set signup UUID as:', currentSignup)
    }
  }, [signup])

  return (
    <div className="jarvis-signup p-3">
      {serverErrors && serverErrors.link ? (
        <p className="text-center">
          {serverErrors.message}
          <br />
          <a href={serverErrors.link} target="_parent">
            Sign Up
          </a>
        </p>
      ) : (
        <>
          {serverErrors ? (
            <div className="alert alert-danger" role="alert">
              {Object.keys(serverErrors).map(error => {
                return (
                  <div key={error}>
                    {error}: {serverErrors[error][0]}
                  </div>
                )
              })}
            </div>
          ) : null}
          <StepWizard isHashEnabled={true} nav={<Nav />} isLazyMount={true}>
            <Account signup={{...signup, kind: 'scout'}} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
            <Terms signup={{...signup, kind: 'scout'}} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
            <Schedule signup={{...signup, kind: 'scout'}} setSignup={setSignup} setServerErrors={setServerErrors} todaysDate={todaysDate} {...props} />
            <Complete signup={{...signup, kind: 'scout'}} setSignup={setSignup} setServerErrors={setServerErrors} {...props} />
          </StepWizard>
        </>
      )}
    </div>
  )
}

export default Signup
