import { add, getDate, getHours, getMinutes, getMonth, isAfter, isBefore, isSameWeek, isWeekend, isFriday, isSaturday, sub, parseISO } from "date-fns"
const getJobs = async ({csrf_token, calendar_entities, setServerErrors, setTimeSlots, setSubmitting}) => {
  // const entityArray = JSON.stringify(calendar_entities.split(','))
  const response = await fetch('/scheduling/time_slots', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token,
    },
  })
  const respData = await response.json()
  if (respData !== null) {
    setTimeSlots(respData)
    setSubmitting(false)
    if (respData.errors) {
      setServerErrors(respData.errors)
    }
  }
}
const minDatePlusWeekend = (todaysDate, schedule_minimum_days) => {
  const min = parseInt(schedule_minimum_days)
  if (min == 0) {
    return sub(todaysDate, {days: 1})
  } else if (min < 3 && isSaturday(todaysDate)) {
    return add(todaysDate, {days: (min || 5) + 1})
  } else if (min < 3 && isFriday(todaysDate)) {
    return add(todaysDate, {days: (min || 5) + 2})
  } else {
    return add(todaysDate, {days: min || 5})
  }
}
const currentDayTimeSlots = (timeSlots, selectedDate) => {
  if (timeSlots) {
    const currentTimeSlots = timeSlots[getMonth(selectedDate) + '/' + getDate(selectedDate)] || []

    const currentTime = new Date()
    const currentIndex = currentTimeSlots.findIndex((timeSlot) => {
      const parsedDate = parseISO(timeSlot.start)
      return getHours(parsedDate) === getHours(currentTime) && getMonth(parsedDate) === getMonth(currentTime) && getDate(parsedDate) === getDate(currentTime)
    })
    let minimumStart
    if (currentIndex > 0) {
      const parsedDateOfCurrentTimeslot = parseISO(currentTimeSlots[currentIndex].end)
      minimumStart = add(parsedDateOfCurrentTimeslot, {minutes: 59})
    } else {
      minimumStart = add(currentTime, {hours: 2})
    }

    return currentTimeSlots.filter((timeSlot) => {
      const parsedDate = parseISO(timeSlot.start)
      return parsedDate > minimumStart
    })
  } else {
    return []
  }
}
const isTheSameHourAndMinutes = (event, compare) => {
  return getHours(event) === getHours(compare) && getMinutes(event) === getMinutes(compare)
}
const tileDisabled = ({date, view, minDate, maxDate, timeSlots}) => {
  if (view === 'month') {
    if (isWeekend(date)) return true
    if (isBefore(date, minDate)) return true
    if (isAfter(date, maxDate)) return true
    return currentDayTimeSlots(timeSlots, date).filter((timeSlot) => timeSlot.available === true).length === 0
  }
}
const tileClassName = ({date, dateValue = false, increment = 'minute', view, minDate, maxDate, timeSlots}) => {
  if (view === 'month') {
    if (dateValue && (increment === 'week')) {
      if (isSameWeek(date, dateValue) && !isWeekend(date)) return 'week-selected'
    }
    if (isWeekend(date)) return 'day-not-available'
    if (isBefore(date, minDate)) return 'day-not-available'
    if (isAfter(date, maxDate)) return 'day-not-available'
    if (currentDayTimeSlots(timeSlots, date).filter((timeSlot) => timeSlot.available === true).length === 0) return 'day-not-available'
    return 'day-available'
  }
}
export {tileDisabled, tileClassName, minDatePlusWeekend, getJobs, currentDayTimeSlots, isTheSameHourAndMinutes}