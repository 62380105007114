import React, {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'

const initialFields = [
  {
    id: 'first_name',
    autocomplete: 'given-name',
    placeholder: 'First Name',
    name: 'service_signup[first_name]',
    registerOpts: {
      required: 'Please provide your first name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'last_name',
    autocomplete: 'family-name',
    placeholder: 'Last Name',
    name: 'service_signup[last_name]',
    registerOpts: {
      required: 'Please provide your last name',
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  // {
  //   id: 'company_name',
  //   autocomplete: '',
  //   placeholder: 'Company Name',
  //   name: 'service_signup[company_name]',
  //   registerOpts: {
  //     minLength: {value: 2, message: 'Requires minimum of 2 characters'},
  //   },
  // },
  {
    id: 'email',
    autocomplete: 'email',
    placeholder: 'Email',
    name: 'service_signup[email]',
    registerOpts: {
      required: 'Please provide your email',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
      minLength: {value: 3, message: 'Requires minimum of 3 characters'},
    },
  },
  {
    id: 'phone',
    autocomplete: 'tel tel-national',
    placeholder: 'Phone Number',
    name: 'service_signup[phone]',
    registerOpts: {
      required: 'Please provide your phone number',
      pattern: {
        value: /^[0-9]{10}$/,
        message: 'Phone Number must be 10 digits with only numbers',
      },
    },
  },
  {
    id: 'address_street',
    autocomplete: 'street-address',
    placeholder: 'Street Address',
    name: 'service_signup[address_street]',
    registerOpts: {required: 'Please provide your street address'},
  },
  {
    id: 'address_city',
    autocomplete: 'address-level2',
    placeholder: 'City',
    name: 'service_signup[address_city]',
    registerOpts: {required: 'Please provide your city'},
  },
  {
    id: 'address_zip',
    autocomplete: 'postal-code',
    placeholder: 'Zip',
    name: 'service_signup[address_zip]',
    registerOpts: {
      required: 'Please provide your zip',
      pattern: {
        value: /^\d{5}$/,
        message: 'must be 5 numbers',
      },
    },
  },
  {
    id: 'neighborhood_name',
    autocomplete: '',
    placeholder: 'What Neighborhood are you in?',
    name: 'service_signup[neighborhood_name]',
    registerOpts: {
      minLength: {value: 2, message: 'Requires minimum of 2 characters'},
    },
  },
  {
    id: 'current_provider',
    autocomplete: '',
    placeholder: 'Who is your current internet provider?',
    name: 'service_signup[current_provider]',
    registerOpts: {},
  },
  {
    id: 'current_speeds',
    autocomplete: '',
    placeholder: 'What speeds are you currently getting?',
    name: 'service_signup[current_speeds]',
    registerOpts: {},
  },
]

const Account = ({setSignup, name, csrf_token, setServerErrors, ...props}) => {
  const {register, handleSubmit, formState: { errors }} = useForm()
  const [submitting, setSubmitting] = useState(null)

  const onSubmit = async formData => {
    if (submitting) {
      return
    }

    setServerErrors(null)
    setSubmitting(true)
    // const {address_state, ...filteredSignup} = signup

    const response = await fetch('/request_signup/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        service_signup: {...formData.service_signup, entity: name, kind: 'request', signup_state: 'account'},
      }),
    })

    let respData = await response.json()

    if (respData !== null) {
      setSignup(respData.data)
      setSubmitting(false)
      if (respData.errors) {
        setServerErrors(respData.errors)
      } else {
        props.nextStep()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container-fluid py-3 px-5">
        <div className="form-row">
          {initialFields.map(field => {
            return (
              <div key={field.id} className="col-md-6 mb-2">
                <input
                  placeholder={field.placeholder}
                  id={field.id}
                  name={field.name}
                  autoComplete={field.autocomplete}
                  className={errors?.service_signup && errors.service_signup[field.id] ? 'is-invalid form-control' : 'form-control'}
                  {...register(field.name, field.registerOpts)}
                ></input>
                {errors?.service_signup && errors?.service_signup[field.id] ? (
                  <div className="invalid-feedback">
                    {(errors?.service_signup && errors.service_signup[field.id].message) || 'This field is required'}
                  </div>
                ) : null}
              </div>
            )
          })}
          <div className="col-12 text-right">
            <button className="btn btn-primary" disabled={submitting}>
              Submit Details
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Account
