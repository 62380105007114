import React, { useState, useEffect } from 'react';

const Header = ({ props }) => {
  const { signup, tags, entity_name } = props;

  return (
    <div className="page-header-content header-elements-lg-inline">
      <div className="page-title d-flex jarvis-breadcrumbs">
        <h4>
          <i className="icon-arrow-right13 mr-2"></i>
          <a href={`/crm/${entity_name}/signups`}>
            <span className="capitalize">{entity_name} Signups</span>
          </a> - <i className={`icon-user mr-1 opacity-70`}></i>{signup?.name}&nbsp;
          {tags.map(tag => (
            <span key={tag.id} className="badge badge-secondary" style={{backgroundColor: tag.color}}>{tag.name}</span>
          ))}
        </h4>
        <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
      </div>

      <div className="header-elements d-none text-center text-lg-left mb-3 mb-lg-0">
        <div className="btn-group position-static m-2">
          <a href={`${signup.legacy_path}`} className="btn btn-primary">
            <i className="icon-user mr-2"></i> Legacy Signup view
          </a>
        </div>
        {signup.customer_path ? (
          <div className="btn-group position-static m-2">
            <a href={`${signup.customer_path}`} className="btn btn-primary">
            <i className="icon-user mr-2"></i> Customer profile
            </a>
          </div>
        ) : ''}
        {signup.crm_client_url ? (
          <div className="btn-group position-static m-2">
            <a href={`${signup.crm_client_url}`} className="btn btn-primary">
              CRM Client profile
            </a>
          </div>
        ) : ''}
        <div className="btn-group position-static">
          <a href={`/crm/${entity_name}/signups/${signup?.id}/bookable_appointments/new`} className="btn btn-indigo">
            <i className="icon-stack2 mr-2"></i> Create Appointment
          </a>
          <button type="button" className="btn btn-indigo dropdown-toggle" data-toggle="dropdown" aria-expanded="false"></button>
          <div className="dropdown-menu dropdown-menu-right" style={{}}>
            <div className="dropdown-header">Links</div>
            <a href={`/crm/${entity_name}/signups/${signup?.id}/bookable_appointments`} className="dropdown-item" target="_blank">
              Bookable Appointments
            </a>
            {signup?.crm_client_url ? 
              <a href={signup?.crm_client_url} className="dropdown-item" target="_blank">
                <i className="icon-file-eye"></i> UCRM
              </a>
            : ''}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;