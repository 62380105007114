import React, { useState, useMemo, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { useDebounce } from '../helpers/useDebounce'

const CrmSearch = ({csrf_token, entity_name}) => {
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState('');
    const [serverErrors, setServerErrors] = useState('');
    const [submitting, setSubmitting] = useState(false);

    function encodeQueryData(data) {
      const ret = []
      for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
      return ret.join('&')
    }

    const searchRecords = useDebounce(async () => {
      if (submitting) return
      setSubmitting(true)
      const query = {
        q: search,
        entity_name: entity_name,
      }

      const queryString = encodeQueryData(query)

      const response = await fetch(`/crm/search?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf_token,
        },
      })
      const respData = await response.json()
      if (respData !== null) {
        setRecords(respData)
        setSubmitting(false)
        if (respData.errors) {
          setServerErrors(respData.errors)
        }
      }
    })

    const handleSearchChange = (event) => {
      setSearch(event.target.value);

      searchRecords();

      // if (event.target.value.length > 2) {
      //   searchRecords();
      // }
    };

    const goToRecord = (model, id) => () => {
      window.location = `/crm/${entity_name}/${model}/${id}`
    }

    function highlightText(text, highlight) {
      // Split text on highlight term, include term itself into parts, ignore case
      const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
      return (
        <span>
          {parts?.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: 'yellow' }}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </span>
      );
    }
    return (
      <div className="form-group-feedback form-group-feedback-left flex-grow-1">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
          />
          <div className="form-control-feedback">
              <i className="icon-search4 opacity-50"></i>
              {/* <i className="icon-cross2 float-right" onClick={() => (setSearch('') && setRecords(''))}></i> */}
          </div>
          {/* <div className="form-control-feedback float-right">
            
          </div> */}
          
          <div className="nav-item dropdown">
        <div className={`dropdown-menu dropdown-content ${records ? 'show' : ''}`}>
            <div className="table-responsive wmin-600">
                <table className="table table-sm">
                    <tbody>
                      {records ? records.filter((r) => r.entity_name === entity_name).map((record) => (
                        <tr key={`${entity_name}-${record.id}`} onClick={goToRecord(record.model_name_plural, record.id)} className='btn-white'>
                          <td>
                          <i className={`icon-${record.icon} mr-1 opacity-70`}></i> <b>{highlightText(record.name, search)}</b> {record.tags ? record.tags.map((tag) => <span className="badge badge-secondary ml-1" style={ { backgroundColor: tag.color, fontSize: "0.7rem" } }>{tag.name}</span> ) : null}
                            <span className="d-block font-size-sm text-muted">
                              {record.model_name} ID: {record.id} - {highlightText(record.email, search)} - {highlightText(record.phone, search)} - {highlightText(record.address, search)}
                            </span>
                          </td>
                          {/* 
                          <td>{highlightText(record.email, search)}</td>
                          <td>{highlightText(record.phone, search)}</td> */}
                        </tr>
                      )) : null}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
      </div>
    )
}

export default CrmSearch
