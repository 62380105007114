import React, {useState, ReactDOM, useEffect} from 'react'
import moment from 'moment'

const Audits = ({events, action_types, ...props}) => {
  const [activeTab, setActiveTab] = useState('all')
  const [filteredEvents, setFilteredEvents] = useState(events)

  useEffect(() => {
    if (activeTab === 'all') {
      setFilteredEvents(events)
    } else {
      setFilteredEvents(events.filter(event => event.action === activeTab))
    }
  }, [activeTab])

  const showModal = (type, event) => {
    if (type === 'edit') {
      console.log('Edit Event', event)
    } else {
      console.log('Show Event', event)
    }
  }

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Audit Feed</h6>
        <div className="header-elements">
          <span className="badge badge-info badge-pill">{events.length}</span>
        </div>
      </div>

      <ul className="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
        <li className="nav-item">
          <a href="#" className={"nav-link active"} data-toggle="tab" onClick={() => setActiveTab('all')} >All</a>
        </li>
        {action_types.map((action, index) => {
          return (
            <li className="nav-item" key={index}>
              <a href={`#messages-${action}`} className={"nav-link"} data-toggle="tab" onClick={() => setActiveTab(action.value)}> {action.name} </a>
            </li>
          )
        })}
      </ul>

      <div className="card-body">
        <div className="chart mb-3" id="bullets"></div>

        <ul className="media-list">
          {filteredEvents.map((event, index) => {
            return (
              <li key={index} className="media">
                <div className="media-body">
                  <div>{event.id} - {event.action} {event.user_id ? `by user: ${event.user_id}` : ''}</div>
                  <div>{event.comment}</div>
                  {/* <div>{JSON.stringify(event.audited_changes)}</div> */}
                  <div className="text-muted" title={moment(event.created_at).format('MMMM Do YYYY, h:mm:ss a')}>{moment(event.created_at).fromNow()}</div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>

  )
}

export default Audits