import React, {useState, ReactDOM} from 'react'
import Header from './Header'
import BookableAppointmentList from '../bookable_appointments/BookableAppointmentList'
import Audits from '../Audits'

const Show = ({csrf_token, ...props}) => {

  const {signup,entity_name} = props;
  return (
    <div className="content">
      <Header props={props} />
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-header header-elements-inline">
            <h5 className="card-title capitalize">Signup Info</h5>
            </div>

            <div className="collapse show">
              <div className="card-body">
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Kind</div>
                    <div className='col-9'>
                      {signup.kind}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Signup Step</div>
                    <div className='col-9'>
                      {signup.signup_state}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Plan</div>
                    <div className='col-9'>
                      {signup.plan_name}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Addons</div>
                    <div className='col-9'>
                      {signup.addon_names}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Promotion Code</div>
                    <div className='col-9'>
                      {signup.promotion_code}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Referred by</div>
                    <div className='col-9'>
                      {signup.referred_by}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Company Name</div>
                    <div className='col-9'>
                      {signup.company_name}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>First Name</div>
                    <div className='col-9'>
                      {signup.first_name}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Last Name</div>
                    <div className='col-9'>
                      {signup.last_name}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Email</div>
                    <div className='col-9'>
                      {signup.email}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Phone</div>
                    <div className='col-9'>
                      {signup.phone}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Address Street</div>
                    <div className='col-9'>
                      {signup.address_street}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Address City</div>
                    <div className='col-9'>
                      {signup.address_city}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Address Zip</div>
                    <div className='col-9'>
                      {signup.address_zip}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Latitude</div>
                    <div className='col-9'>
                      {signup.latitude}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Longitude</div>
                    <div className='col-9'>
                      {signup.longitude}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>stripe_customer_uid</div>
                    <div className='col-9'>
                      <a href={`https://dashboard.stripe.com/customers/${signup.stripe_customer_uid}`}>{signup.stripe_customer_uid}</a>
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>CRM Client UID</div>
                    <div className='col-9'>
                      <a href={signup.crm_client_url}>{signup.crm_client_uid}</a>
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>CRM Ticket UID</div>
                    <div className='col-9'>
                      <a href={signup.crm_ticket_url}>{signup.crm_ticket_uid}</a>
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Customer ID</div>
                    <div className='col-9'>
                      <a href={`/crm/${entity_name}/customers/${signup.customer_id}`}>{signup.customer_id}</a>
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Original Installation Date</div>
                    <div className='col-9'>
                      {signup.installation_date}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Original Scout Week Of Date</div>
                    <div className='col-9'>
                      {signup.scout_week_of}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Created at</div>
                    <div className='col-9'>
                      {signup.created_at}
                    </div>
                  </div>
                  <div className="row border-bottom p-1">
                    <div className='col-3'>Updated at</div>
                    <div className='col-9'>
                      {signup.updated_at}
                    </div>
                  </div>
                  <div className="text-right">
                    <a href={`/crm/${entity_name}/signups/${signup.id}/edit`} className="btn btn-primary">Edit</a>
                  </div>
              </div>
            </div>
          </div>
          <Audits events={props.audits} action_types={props.auditable_types} />
        </div>
        <div className='col-12 col-lg-6'>
          <div className="card">
            <div className="card-header header-elements-inline">
              <h5 className="card-title capitalize">Stripe Info</h5>
            </div>
            <div className='card-body'>
              {signup.stripe_customer_uid ? (
                <>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Metadata:</div>
                    <div className='col-9'>{JSON.stringify(signup.stripe_customer_details.metadata)}</div>
                  </div>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Address:</div>
                    <div className='col-9'>{JSON.stringify(signup.stripe_customer_details.address)}</div>
                  </div>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Delinquent:</div>
                    <div className='col-9'>{signup.stripe_customer_details.delinquent || 'False'}</div>
                  </div>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Phone:</div>
                    <div className='col-9'>{signup.stripe_customer_details.phone}</div>
                  </div>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Name:</div>
                    <div className='col-9'>{signup.stripe_customer_details.name}</div>
                  </div>
                  <div className='row p-1'>
                    <div className='col-3 bold'>Email:</div>
                    <div className='col-9'>{signup.stripe_customer_details.email}</div>
                  </div>
                </>
              ) : <p>Provide stripe_customer_uid to see details</p>}
            </div>
          </div>
          <BookableAppointmentList 
            bookable_appointments={props.bookable_appointments} 
            bookable_appointment_templates={props.appointment_templates} 
            index_link={`/crm/${props.entity_name}/signups/${signup.id}/bookable_appointments`} 
          /> 
        </div>
      </div>
    </div>
  )
}

export default Show;